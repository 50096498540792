<template>
  <div class="app-container">
    <nav>
      <div class="tips">
        <el-button @click="imports" type="primary" class="btn">上传</el-button>
        <el-button @click="showTips" type="primary" class="btn">提示</el-button>
        <el-button @click="showPdf" type="primary" class="btn">预览PDF</el-button>
        <el-button @click="showOfd" type="primary" class="btn">预览OFD</el-button>
      </div>
    </nav>

    <div class="insurance_table">
      <comps-table :tableData="tableData" :tableHeader="tableHeader" v-loading='isLoading' :hideSinglePage='false' class="comp_table" v-if="hasData">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" v-loading="isLoading" />
    </div>

    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config">
      <div class="answer">亲，确认要删除吗？</div>
    </del-dialog>

    <pdf-dialog :visible='pdfVis' :cancelDialog='pdfCancel'/>
    <ofd-dialog :visible='ofdVis' :cancelDialog='ofdCancel'/>
    <import-dialog :visible='importVis' :cancelDialog='importCancel'/>
    <tips-dialog :visible="promptVisible" :cancelDialog='tipsCancel'></tips-dialog>

  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import { insurancesList, deleteInsurances } from '@/api/insurances'
  import importDialog from './dialog/import'
  import pdfDialog from './dialog/showPdf'
  import ofdDialog from './dialog/showOdf'
  import tipsDialog from './dialog/showTips'
  export default {
    components: { delDialog ,compsTable , importDialog , pdfDialog ,ofdDialog , tipsDialog},
    data() {
      return {
        isLoading: false,
        hasData: true,
        tableHeader: [
          { prop: "name", label: "缴纳项目", center: 'left' ,
          render: (h, data) => {
              return (
                <span>
                  {data.row.name}
                </span>
              );
            },
          },
          { prop: "companyRatio", label: "公司缴纳比例", center: 'left',
            formatter: (row) => {
              return row.companyRatio + '%';
            },
          },
          { prop: "personalRatio", label: "个人缴纳比例", center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.personalRatio + '%'}
                </span>
              );
            },
          },
          {
            label: "操作",
            center: 'left' ,
            render: (h, data) => {
              if(data.row.isDefault == '1'){
                return (
                  <span>
                    <el-button class='button'  type="text" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                  </span>
                );
              }else{
                return (
                  <span>
                    <el-button class='button' type="text" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                      <el-button class='button' type="text" onClick={() => { this.delete(data.row);}}>
                        删除
                      </el-button>
                  </span>
                );
              }
            },
          },
        ],
        tableData: [],
        isdeling: false,
        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保险id
        id: '',

        delDialog: false,
        importVis: false,
        pdfVis: false,
        ofdVis: false,

        promptVisible : false,
        icon : 'iconfont iconicon1-17',
        color: '#35C4B1',
        message : '修改提示',
      }
    },
    methods: {
      add() {
        this.$router.push('insuranceSet/add')
      },
      edit(row) {
        this.$router.push({
          path: 'insuranceSet/edit',
          query: {
            name: row.name,
            companyRatio: row.companyRatio,
            personalRatio: row.personalRatio,
            isDefault: row.isDefault,
            id: row.id
          }
        })
      },
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },
      confirmDialog() {
        this.isdeling = true
        deleteInsurances({
          asId: this.getToken('asId'),
          id: this.id
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delDialog = false
            this.getInsurancesList()
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
      },
      // 五险一金列表
      getInsurancesList() {
        this.isLoading = true;
        insurancesList(
          {
            asId: this.getToken('asId')
          }
        ).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
         // einvAlert.error("提示",err)
        })
      },

      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
      showPdf(){
        this.pdfVis = true;
      },
      pdfCancel(){
        this.pdfVis = false;
      },
      showOfd(){
        this.ofdVis = true;
      },
      ofdCancel(){
        this.ofdVis = false;
      },

      showTips(){
        this.promptVisible = true;
      },
      tipsCancel(){
        this.promptVisible = false;
      }
    },
    mounted() {
      this.getInsurancesList()
    }
  }
</script>

<style lang="less">
  .button{
    margin-right: 10px;
  }
  // .insurance_table {
  //   height: calc(100% - 60px);
  //   padding: 24px 20px;
  //   background-color: #fff;
  //   .comp_table {
  //     border: 1px solid #ebeef5;
  //   }
  // }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
</style>
